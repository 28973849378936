import React from "react";
import {
  Button,
  Dropdown
} from "rsuite";

export default function FilterDropdown({
  filterItems,
  filterItem,
  resetFilter,
}) {
  return (
    <div style={{ margin: "11px 0 0 5px", padding: "0" }}>
      {filterItem.length > 2 && (
        <Dropdown title="Filter">
          {filterItem.map((item, id) => {
            return (
              <Dropdown.Item onSelect={() => filterItems(item)}>
                {item}
              </Dropdown.Item>
            );
          })}
        </Dropdown>
      )}

      {filterItem.length < 2 && (
        <Button appearance="primary" onClick={() => resetFilter()}>
          Toon alle
        </Button>
      )}
    </div>
  );
}
