import * as React from "react";
import { Panel, Grid, Row, Col } from "rsuite";
import LayoutMain from "components/layout/main";
import MapRegioPage from "components/map/mapRegioPage";
import SectionWrapper from "../../components/section/sectionWrapper";

const RegioPage = () => {
  return (
    <LayoutMain>
      <MapRegioPage />
      <SectionWrapper padded background>
        <Grid>
          <Row style={{ marginBottom: "20px" }}>
            <Col xs={24} md={16}>
              <h3
                style={{
                  marginBottom: "40px",
                  color: "#000",
                  textAlign: "center",
                }}
              >
                Veelgestelde vragen
              </h3>
              <Panel
                header="Wat is een samenwerkingsverband?"
                collapsible
                bordered
                style={{ marginTop: "40px", backgroundColor: "white" }}
              >
                <p style={{ fontSize: "16px" }}>
                  Een samenwerkingsverband is een lokale samenwerking,
                  bijvoorbeeld tussen een ziekenhuis, trombosedienst en
                  huisartsen.
                </p>
              </Panel>
              <Panel
                header="Wat is een expertisecentrum?"
                collapsible
                bordered
                style={{ marginTop: "20px", backgroundColor: "white" }}
              >
                <p style={{ fontSize: "16px" }}>
                  Een expertisecentrum is een samenwerkingsverband, waarbij een
                  academisch of groter (topklinisch) ziekenhuis een spilfunctie
                  vervult. Het Expertisecentrum vervult in de regio een aantal
                  belangrijke taken:
                </p>
                <p style={{ fontSize: "16px" }}>
                  <ul>
                    <li>
                      geeft advies bij vragen rond antistollingszorg en is
                      daartoe 7 dagen, 24 uur bereikbaar;
                    </li>
                    <li>
                      wordt betrokken bij overleg over complexe pathologie op
                      gebied van trombose en hemostase en is het centrum waar
                      patiënten met dergelijke complexe pathologie naar verwezen
                      kunnen worden;
                    </li>
                    <li>
                      is verantwoordelijk voor het up-to-date houden van
                      regionale protocollen en de afstemming daarvan binnen de
                      regio; coördineert in nauwe samenspraak met alle relevante
                      partijen de organisatie van de antistollingszorg in de
                      regio;
                    </li>
                    <li>
                      bevordert regionale nascholing; bevordert wetenschappelijk
                      onderzoek in regionaal verband op gebied van
                      antitrombotische diagnostiek en behandeling.
                    </li>
                  </ul>
                </p>
              </Panel>
            </Col>
          </Row>
        </Grid>
      </SectionWrapper>
    </LayoutMain>
  );
};

export default RegioPage;
