import React, { useState } from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import { Row, Col, Popover, Whisper, Tag, Divider } from "rsuite";
import SectionWrapper from "../section/sectionWrapper";
import GoogleMapReact from "google-map-react";
import FilterDropdown from "./ui/filterDropdown";
import SearchInput from "./ui/searchInput";
import RegioMapList from "./ui/regioMapList";

import { Icon } from "@rsuite/icons";
import {
  BiTestTube,
  BiPlusMedical,
  BiMapPin,
  BiCheckDouble,
  BiCheck,
  BiX,
} from "react-icons/bi";
import { BrowserView, isMobile } from "react-device-detect";
export default function MapRegioPage() {
  const data = useStaticQuery(graphql`
    query {
      allOrganisatiesCsv {
        nodes {
          id
          lat
          lng
          markerSize
          slug
          title
          type
          subtype
          city
          logo
          province
          sources
        }
      }
      siteSearchIndex {
        index
      }
    }
  `);

  const [items, setItems] = useState(data.allOrganisatiesCsv.nodes);
  const [filterItem, setFilterItem] = useState([
    ...new Set(items.map((item) => item.type)),
  ]);
  const [zoomLevel, setZoomLevel] = useState("8");

  const filterItems = (curcat, map, maps) => {
    const newItems = items.filter((item) => {
      return item.type === curcat;
    });
    setItems(newItems);
    setFilterItem([...new Set(newItems.map((node) => node.type))]);
  };

  const resetFilter = () => {
    setItems(data.allOrganisatiesCsv.nodes);
    setFilterItem([
      ...new Set(data.allOrganisatiesCsv.nodes.map((item) => item.type)),
    ]);
  };

  const sortedItems = items.sort(function (a, b) {
    if (a.province.toLowerCase() < b.province.toLowerCase()) return -1;
    if (a.province.toLowerCase() > b.province.toLowerCase()) return 1;
    return 0;
  });

  const groupedItems = sortedItems.reduce((items, item) => {
    if (items[item.province]) {
      items[item.province].push(item);
    } else {
      items[item.province] = [item];
    }
    return items;
  }, {});

  const getMapBounds = (map, maps, items) => {
    const bounds = new maps.LatLngBounds();

    items.forEach((place) => {
      bounds.extend(new maps.LatLng(place.lat, place.lng));
    });
    return bounds;
  };

  // Re-center map when resizing the window
  const bindResizeListener = (map, maps, bounds) => {
    maps.event.addDomListenerOnce(map, "idle", () => {
      maps.event.addDomListener(window, "resize", () => {
        map.fitBounds(bounds);
      });
    });
    maps.event.addDomListener(map, "zoom_changed", () => {
      setZoomLevel(map.getZoom());
    });
  };

  // Fit map to its bounds after the api is loaded
  const apiIsLoaded = (map, maps, items) => {
    // Get bounds by our places
    const bounds = getMapBounds(map, maps, items);
    // Fit map to bounds
    map.fitBounds(bounds);
    // Bind the resize listener
    bindResizeListener(map, maps, bounds);
  };

  return (
    <>
      <SectionWrapper>
        <Row
          style={{
            backgroundColor: "#fff",
            padding: isMobile ? "0 0 20px 0" : "0",
            margin: "0",
          }}
        >
          <Col
            xs={24}
            sm={6}
            md={6}
            style={{ maxWidth: "100vw", backgroundColor: "#fff", padding: "0 10px" }}
          >
            <h3 style={{ color: "#000", margin: "20px 0 10px 0" }}>Organisatie vinden</h3>
            <Row>
              <Col sm={17}>
                <SearchInput
                  searchIndex={data.siteSearchIndex.index}
                  getResults={setItems}
                  resetData={data.allOrganisatiesCsv.nodes}
                />
              </Col>
              <Col sm={6}>
                <FilterDropdown
                  filterItems={filterItems}
                  filterItem={filterItem}
                  resetFilter={resetFilter}
                />
              </Col>
            </Row>
            <RegioMapList groupedItems={groupedItems} />
          </Col>
          <BrowserView>
            <Col
              xs={24}
              sm={18}
              md={18}
              style={{
                padding: "0",
                height: "92.5vh",
              }}
            >
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: "AIzaSyBUxhEM5nehVOVEUAg11-fQrDLmPc8gASg",
                }}
                center={defaultProps.center}
                zoom={defaultProps.zoom}
                options={defaultProps}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) =>
                  apiIsLoaded(map, maps, items)
                }
              >
                {items.map((node) => {
                  if (node.type === "Trombosedienst") {
                    return (
                      <Marker
                        key={node.id}
                        lat={node.lat}
                        lng={node.lng}
                        title={node.title}
                        slug={node.slug}
                        type={node.type}
                        city={node.city}
                        color="#d78822"
                        icon={BiTestTube}
                        logo={node.logo}
                        small
                      />
                    );
                  } else if (node.type === "Ziekenhuis") {
                    return (
                      <Marker
                        key={node.id}
                        lat={node.lat}
                        lng={node.lng}
                        title={node.title}
                        slug={node.slug}
                        type={node.type}
                        city={node.city}
                        color="#22d788"
                        icon={BiPlusMedical}
                        sources={node.sources}
                        logo={node.logo}
                      />
                    );
                  } else {
                    return (
                      <MarkerRegio
                        key={node.id}
                        lat={node.lat}
                        lng={node.lng}
                        title={node.title}
                        markerSize={node.markerSize}
                        slug={node.slug}
                        type={node.type}
                        city={node.city}
                        color="#2272d7"
                        icon={BiMapPin}
                        logo={node.logo}
                        small
                        zoomLevel={zoomLevel}
                      />
                    );
                  }
                })}
              </GoogleMapReact>
              <div
                style={{
                  position: "absolute",
                  bottom: "30px",
                  left: "10px",
                  zIndex: "100",
                  backgroundColor: "rgba(255,255,255,0.9)",
                  padding: "2px 10px 5px 10px",
                  borderRadius: "5px",
                  fontSize: "10px",
                }}
              >
                <>
                  <span style={{ fontWeight: "bold", marginRight: "15px" }}>
                    Legenda:
                  </span>
                </>
                <>
                  Ziekenhuis
                  <div
                    style={{
                      backgroundColor: "#fff",
                      border: "solid 1px",
                      borderColor: "#22d788",
                      borderRadius: "1000px",
                      color: "#22d788",
                      display: "inline-block",
                      height: "14px",
                      position: "relative",
                      width: "14px",
                      top: "3px",
                      marginLeft: "5px",
                    }}
                  >
                    <Icon
                      as={BiPlusMedical}
                      size="10px"
                      color="#22d788"
                      style={{
                        position: "absolute",
                        top: "1px",
                        left: "1px",
                      }}
                    />
                  </div>
                </>
                <Divider vertical /> <></>
                <>
                  Trombosedienst
                  <div
                    style={{
                      backgroundColor: "#fff",
                      border: "solid 1px",
                      borderColor: "#d78822",
                      borderRadius: "1000px",
                      color: "#d78822",
                      display: "inline-block",
                      height: "14px",
                      position: "relative",
                      width: "14px",
                      top: "3px",
                      marginLeft: "5px",
                    }}
                  >
                    <Icon
                      as={BiTestTube}
                      size="10px"
                      color="#d78822"
                      style={{
                        position: "absolute",
                        top: "1px",
                        left: "1px",
                      }}
                    />
                  </div>
                </>
                <Divider vertical /> <></>
                <>
                  Regionaal samenwerkingsverband
                  <div
                    style={{
                      backgroundColor: "#fff",
                      border: "solid 1px",
                      borderColor: "#2272d7",
                      borderRadius: "1000px",
                      color: "#2272d7",
                      display: "inline-block",
                      height: "14px",
                      position: "relative",
                      width: "14px",
                      top: "3px",
                      marginLeft: "5px",
                    }}
                  >
                    <Icon
                      as={BiMapPin}
                      size="10px"
                      color="#2272d7"
                      style={{
                        position: "absolute",
                        top: "1px",
                        left: "1px",
                      }}
                    />
                  </div>
                </>
              </div>
            </Col>
          </BrowserView>
        </Row>
      </SectionWrapper>
    </>
  );
}

const Marker = ({
  title,
  key,
  slug,
  color,
  icon,
  small,
  type,
  city,
  logo,
  sources,
}) => (
  <Whisper
    placement="top"
    trigger="hover"
    controlId="control-id-hover"
    key={key}
    followCursor={true}
    speaker={
      <Popover>
        {logo && <img src={`/logo/organisaties/${logo}`} alt={title} style={{ width: "80px" }} />}
        <h6 style={{ color: "#000" }}>{title}</h6>
        {city}
        <br />
        <Tag size="sm" style={{ backgroundColor: color, color: "white" }}>
          {type}
        </Tag>
        {sources === "yes" && (
          <Tag>
            <BiCheckDouble
              style={{ position: "relative", top: "2px", marginRight: "5px" }}
            />
            Protocollen zijn openbaar
          </Tag>
        )}
        {sources === "locked" && (
          <Tag>
            <BiCheck
              style={{ position: "relative", top: "2px", marginRight: "5px" }}
            />
            Protocollen zijn besloten
          </Tag>
        )}
        {sources === "no" && (
          <Tag>
            <BiX
              style={{ position: "relative", top: "2px", marginRight: "5px" }}
            />
            Geen protocollen beschikbaar
          </Tag>
        )}
      </Popover>
    }
  >
    <Link to={`/professional/organisatie/profiel/${slug}`}>
      <div
        style={{
          backgroundColor: "#fff",
          borderColor: color,
          color: color,
          borderRadius: "1000px",
          border: "solid 1px",
          position: "relative",
          boxShadow: "0 -3px 1px rgba(0,0,0,0.2)",
          width: small ? "28px" : "32px",
          height: small ? "28px" : "32px",
          top: small ? "-14px" : "-16px",
          left: small ? "-14px" : "-16px",
          zIndex: small ? "1" : "2",
        }}
      >
        <Link to={`/professional/organisatie/profiel/${slug}`}>
          <Icon
            as={icon}
            size={small ? "1.8em" : "2.1em"}
            color={color}
            style={{ position: "absolute", top: "3px", left: "4px" }}
          />
        </Link>
      </div>
    </Link>
  </Whisper>
);

const MarkerRegio = ({
  title,
  key,
  slug,
  color,
  markerSize,
  type,
  city,
  logo,
  zoomLevel,
}) => {
  if (zoomLevel > 10) {
    return (
      <Whisper
        placement="top"
        trigger="hover"
        controlId="control-id-hover"
        key={key}
        followCursor={true}
        speaker={
          <Popover>
            {logo && <img src={logo} alt={title} style={{ width: "80px" }} />}
            <h6>{title}</h6>
            {city}
            <br />
            <Tag size="sm" style={{ backgroundColor: color, color: "white" }}>
              {type}
            </Tag>
          </Popover>
        }
      >
        <div>
          <Link to={`/professional/organisatie/profiel/${slug}`}>
            {markerSize === "sm" && (
              <div
                style={{
                  background:
                    "radial-gradient(circle, rgba(34, 114, 215,0.75) 0%, rgba(0,0,0,0) 90%)",
                  color: color,
                  position: "relative",
                  left: "-400px",
                  top: "-400px",
                  width: "800px",
                  height: "800px",
                  borderRadius: "1000px",
                  zIndex: "0",
                }}
              />
            )}
            {markerSize === "md" && (
              <div
                style={{
                  background:
                    "radial-gradient(circle, rgba(34, 114, 215,0.75) 0%, rgba(0,0,0,0) 90%)",
                  color: color,
                  position: "relative",
                  left: "-480px",
                  top: "-480px",
                  width: "960px",
                  height: "960px",
                  borderRadius: "1000px",
                  zIndex: "0",
                }}
              />
            )}
            {markerSize === "lg" && (
              <div
                style={{
                  background:
                    "radial-gradient(circle, rgba(34, 114, 215,0.75) 0%, rgba(0,0,0,0) 90%)",
                  color: color,
                  position: "relative",
                  left: "-560px",
                  top: "-560px",
                  width: "1120px",
                  height: "1120px",
                  borderRadius: "1000px",
                  zIndex: "0",
                }}
              />
            )}
          </Link>
        </div>
      </Whisper>
    );
  } else if (zoomLevel > 9) {
    return (
      <Whisper
        placement="top"
        trigger="hover"
        controlId="control-id-hover"
        key={key}
        followCursor={true}
        speaker={
          <Popover>
            {logo && <img src={logo} alt={title} style={{ width: "80px" }} />}
            <h6>{title}</h6>
            {city}
            <br />
            <Tag size="sm" style={{ backgroundColor: color, color: "white" }}>
              {type}
            </Tag>
          </Popover>
        }
      >
        <div>
          <Link to={`/professional/organisatie/profiel/${slug}`}>
            {markerSize === "sm" && (
              <div
                style={{
                  background:
                    "radial-gradient(circle, rgba(34, 114, 215,0.75) 0%, rgba(0,0,0,0) 90%)",
                  color: color,
                  position: "relative",
                  left: "-200px",
                  top: "-200px",
                  width: "400px",
                  height: "400px",
                  borderRadius: "1000px",
                  zIndex: "0",
                }}
              />
            )}
            {markerSize === "md" && (
              <div
                style={{
                  background:
                    "radial-gradient(circle, rgba(34, 114, 215,0.75) 0%, rgba(0,0,0,0) 90%)",
                  color: color,
                  position: "relative",
                  left: "-240px",
                  top: "-240px",
                  width: "480px",
                  height: "480px",
                  borderRadius: "1000px",
                  zIndex: "0",
                }}
              />
            )}
            {markerSize === "lg" && (
              <div
                style={{
                  background:
                    "radial-gradient(circle, rgba(34, 114, 215,0.75) 0%, rgba(0,0,0,0) 90%)",
                  color: color,
                  position: "relative",
                  left: "-280px",
                  top: "-280px",
                  width: "560px",
                  height: "560px",
                  borderRadius: "1000px",
                  zIndex: "0",
                }}
              />
            )}
          </Link>
        </div>
      </Whisper>
    );
  } else if (zoomLevel > 8) {
    return (
      <Whisper
        placement="top"
        trigger="hover"
        controlId="control-id-hover"
        key={key}
        followCursor={true}
        speaker={
          <Popover>
            {logo && <img src={logo} alt={title} style={{ width: "80px" }} />}
            <h6>{title}</h6>
            {city}
            <br />
            <Tag size="sm" style={{ backgroundColor: color, color: "white" }}>
              {type}
            </Tag>
          </Popover>
        }
      >
        <div>
          <Link to={`/professional/organisatie/profiel/${slug}`}>
            {markerSize === "sm" && (
              <div
                style={{
                  background:
                    "radial-gradient(circle, rgba(34, 114, 215,0.75) 0%, rgba(0,0,0,0) 90%)",
                  color: color,
                  position: "relative",
                  left: "-100px",
                  top: "-100px",
                  width: "200px",
                  height: "200px",
                  borderRadius: "1000px",
                  zIndex: "0",
                }}
              />
            )}
            {markerSize === "md" && (
              <div
                style={{
                  background:
                    "radial-gradient(circle, rgba(34, 114, 215,0.75) 0%, rgba(0,0,0,0) 90%)",
                  color: color,
                  position: "relative",
                  left: "-120px",
                  top: "-120px",
                  width: "240px",
                  height: "240px",
                  borderRadius: "1000px",
                  zIndex: "0",
                }}
              />
            )}
            {markerSize === "lg" && (
              <div
                style={{
                  background:
                    "radial-gradient(circle, rgba(34, 114, 215,0.75) 0%, rgba(0,0,0,0) 90%)",
                  color: color,
                  position: "relative",
                  left: "-140px",
                  top: "-140px",
                  width: "280px",
                  height: "280px",
                  borderRadius: "1000px",
                  zIndex: "0",
                }}
              />
            )}
          </Link>
        </div>
      </Whisper>
    );
  } else {
    return (
      <Whisper
        placement="top"
        trigger="hover"
        controlId="control-id-hover"
        key={key}
        followCursor={true}
        speaker={
          <Popover>
            {logo && <img src={logo} alt={title} style={{ width: "80px" }} />}
            <h6>{title}</h6>
            {city}
            <br />
            <Tag size="sm" style={{ backgroundColor: color, color: "white" }}>
              {type}
            </Tag>
          </Popover>
        }
      >
        <div>
          <Link to={`/professional/organisatie/profiel/${slug}`}>
            {markerSize === "sm" && (
              <div
                style={{
                  background:
                    "radial-gradient(circle, rgba(34, 114, 215,0.75) 0%, rgba(0,0,0,0) 90%)",
                  color: color,
                  position: "relative",
                  left: "-50px",
                  top: "-50px",
                  width: "100px",
                  height: "100px",
                  borderRadius: "1000px",
                  zIndex: "0",
                }}
              />
            )}
            {markerSize === "md" && (
              <div
                style={{
                  background:
                    "radial-gradient(circle, rgba(34, 114, 215,0.75) 0%, rgba(0,0,0,0) 90%)",
                  color: color,
                  position: "relative",
                  left: "-60px",
                  top: "-60px",
                  width: "120px",
                  height: "120px",
                  borderRadius: "1000px",
                  zIndex: "0",
                }}
              />
            )}
            {markerSize === "lg" && (
              <div
                style={{
                  background:
                    "radial-gradient(circle, rgba(34, 114, 215,0.75) 0%, rgba(0,0,0,0) 90%)",
                  color: color,
                  position: "relative",
                  left: "-70px",
                  top: "-70px",
                  width: "140px",
                  height: "140px",
                  borderRadius: "1000px",
                  zIndex: "0",
                }}
              />
            )}
          </Link>
        </div>
      </Whisper>
    );
  }
};

const defaultProps = {
  center: { lat: 52.1009166, lng: 5.1362914 },
  zoom: 10,
  maxZoom: 11,
  minZoom: 8,
  tilt: 45,
  styles: [
    {
      featureType: "administrative",
      elementType: "all",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "administrative",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#000",
        },
      ],
    },
    {
      featureType: "administrative.province",
      elementType: "all",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "administrative.province",
      elementType: "geometry",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "administrative.province",
      elementType: "labels",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "landscape",
      elementType: "all",
      stylers: [
        {
          color: "#acaaab",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "all",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "all",
      stylers: [
        {
          saturation: -100,
        },
        {
          lightness: 45,
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "all",
      stylers: [
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [
        {
          visibility: "simplified",
        },
        {
          hue: "#c4c3c4",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "labels",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "road.arterial",
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "transit",
      elementType: "all",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "all",
      stylers: [
        {
          color: "#dddbdc",
        },
        {
          visibility: "on",
        },
      ],
    },
  ],
};
